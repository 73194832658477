.parametric-container {
  flex-grow: 1;
  width: 25rem;
  max-width: 100vw;
  padding: 1rem 0 1rem 2rem;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
}

.parametric-menu {
  height: auto;
  overflow: hidden;
  max-height: calc(100vh - 5rem);
}

.parametric-container .legal {
  font-size: 0.8rem;
  margin: .5rem;
}

@media (max-width: 50rem) {
  .parametric-container .legal {
    display: none;
  }
  .parametric-container {
    padding: 0;
    height: 50vh;
  }
}