@font-face {
  font-family: 'buffon';
  src: url('./assets/fonts/buffon-thin-webfont.eot');
  src: url('./assets/fonts/buffon-thin-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/buffon-thin-webfont.woff2') format('woff2'),
       url('./assets/fonts/buffon-thin-webfont.woff') format('woff'),
       url('./assets/fonts/buffon-thin-webfont.ttf') format('truetype'),
       url('./assets/fonts/buffon-thin-webfont.svg#buffonthin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'karla';
  src: url('./assets/fonts/karla-variablefont_wght-webfont.eot');
  src: url('./assets/fonts/karla-variablefont_wght-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/karla-variablefont_wght-webfont.woff2') format('woff2'),
       url('./assets/fonts/karla-variablefont_wght-webfont.woff') format('woff'),
       url('./assets/fonts/karla-variablefont_wght-webfont.ttf') format('truetype'),
       url('./assets/fonts/karla-variablefont_wght-webfont.svg#karlaregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  margin: 0;
  padding: 0;
}
.App {
  display: flex;
  height: 100vh;
}
.App-body {
  display: flex;
  flex-flow: wrap;
  overflow: hidden;
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  margin-left: 6rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 50rem) {
  .App-body {
    margin-left: 0;
  }
}
