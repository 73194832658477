h2 {
  font-weight: normal;
  font-size: 2.2rem;
  margin: 0;
  text-transform: uppercase;
}

.dropdown-arrow {
  margin: 0.1rem 1rem;
  width: 2rem;
  transition: .4s all;
}
.dropdown-container.visible .dropdown-arrow {
  transform: rotate(180deg);
}

.dropdown-container.visible {
  height: 20rem;
}

.dropdown-container {
  position: relative;
  overflow: hidden;
  margin: 1rem;
  height: 3rem;
  transition: height .5s ease-in-out;
}

.dropdown-container.visible .options-list {
  opacity: 100%;
}

.options-list {
  position: absolute;
  opacity: 0%;
  height: 0;
  margin: 0;
  padding: 2px;
  list-style: none;
  transition: opacity 0.5s ease-in-out;
}

.option {
  color: #b4bedb;
  margin: 0;
  font-size: 1.06rem;
  line-height: 1.4rem;
  text-transform: uppercase;
}

.option.selected {
  color: #000000;
  background: none;
}

.option:hover {
  color: #899ACA;
}

