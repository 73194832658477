.container-canvas {
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  max-height: 100vh;
  min-width: 60%;
  max-width: 100vw;
  position: relative;
}

.canvas {
  width: 100%;
}

#img-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

@media (max-width: 50rem) {
  .container-canvas {
    width: 100vw;
    height: 50vh;
    padding: 0;
    position: relative;
  }
}
